<template>
  <div class="mt-1 mx-3">
    <agency-contacts>
    </agency-contacts>
  </div>
</template>

<script>
import AgencyContacts from '@/components/wallet/agency-contacts/index.vue'
export default {
  components: {
    AgencyContacts
  },
}
</script>
