<template>
  <div class="mt-1 mx-3">
    <agency-contacts-create> </agency-contacts-create>
  </div>
</template>

<script>
import AgencyContactsCreate from "@/components/accounting/accounting-agency/agency-contacts/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencyContactsCreate,
  },
  created() {
    this.notifyQueue({
      text: "Crea Anagrafica",
      path: "agency_books.create",
      level: 4,
    });
  },
};
</script>
