<template>
  <div class="mt-1 mx-3">
    <agency-contacts> </agency-contacts>
  </div>
</template>

<script>
import AgencyContacts from "@/components/accounting/accounting-agency/agency-contacts/index.vue";
import { mapActions } from "vuex";

export default {
  components: {
    AgencyContacts,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
};
</script>
